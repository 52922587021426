<script setup lang="ts">
import {
	DialogContent,
	DialogOverlay,
	DialogPortal,
	DialogRoot,
	DialogTrigger,
} from 'radix-vue';
import CatalogueEmptyState from '~/components/catalogue/CatalogueEmptyState.vue';
import {
	getFilteredList,
	getGroupByInitial,
	handleScroll,
} from '~/components/catalogue/utils';
import type { BannerReasons } from '~/components/TopBanner.vue';
import { useFetchAllCollections } from '~/data/collections';

const open = ref(false);
const searchQuery = ref('');
const selectedGroup = ref('');
const topBannerReason = inject('topBannerReason') as Ref<BannerReasons>;

const { data: collections, isLoading } = useFetchAllCollections();

const filteredItemsList = computed(() =>
	getFilteredList(collections.value, searchQuery.value, 'title'),
);
const initialsGroup = computed(() =>
	getGroupByInitial(filteredItemsList.value, 'title'),
);
const handleChange = (value: string) => {
	selectedGroup.value = value;
	handleScroll(value);
};

const updateOpen = () => {
	open.value = !open.value;
};

provide('searchQuery', searchQuery);
provide('selectedGroup', selectedGroup);
</script>

<template>
	<DialogRoot v-model:open="open" @update:open="open != open">
		<DialogTrigger
			class="nav-button hover:bg-gray-50 hover:cursor-pointer hover:rounded-xl"
			@click="open = true"
		>
			<span class="nav-button-text">Top Picks</span>
		</DialogTrigger>

		<DialogPortal>
			<DialogOverlay
				class="bg-gray-800/60 data-[state=open]:animate-overlay-show fixed top-[80px] right-0 left-[250px] bottom-0 z-30"
				:style="
					topBannerReason
						? { top: `calc(var(--laam-shipping-banner-height) + 80px)` }
						: {}
				"
			/>

			<DialogContent
				class="data-[state=open]:animate-content-show fixed bg-white focus:outline-none h-full left-[250px] top-[80px] z-50 grid w-full max-w-lg -translate-x-0 -translate-y-0 gap-3xl border-r !rounded-none duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out !border-none data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95"
				:style="
					topBannerReason
						? { top: `calc(var(--laam-shipping-banner-height) + 80px)` }
						: {}
				"
			>
				<div v-if="!isLoading && collections" class="collections">
					<CatalogueSearchHeader
						path-prefix="collections"
						:initials-group="initialsGroup"
						@update:selected-group="handleChange"
					>
					</CatalogueSearchHeader>

					<CatalogueGroupedListView
						v-if="initialsGroup.length"
						item-key="title"
						path-prefix="collections"
						:initials-group="initialsGroup"
						:filtered-items-list="filteredItemsList"
						@update-open="updateOpen"
					/>
					<CatalogueEmptyState v-else />
				</div>
				<CatalogueSkeleton v-else />
			</DialogContent>
		</DialogPortal>
	</DialogRoot>
</template>

<style>
.nav-button {
	@apply p-xl w-full gap-xxs border-0 flex items-center justify-between;
}

.nav-button-text {
	@apply lg:text-md font-medium text-xxs text-gray-800 capitalize;
}
</style>
